import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import './assets/css/element-variables.scss'
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
Vue.use(Element);
Vue.use(VideoPlayer);
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')